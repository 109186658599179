import React from 'react'

import './work.css'

const Work = () => {
    return (
        <>
            <h2>Work</h2>
        </>
    )
}

export default Work
